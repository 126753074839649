@import '../../styles/shared.scss';


#container {
  position: absolute;
  top:  50%;
  left: 50%;
  transform: translate(-50%, -60%);
}

.logo{
  text-align: center;
}
.welcomeHeader{
  height: 200px;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: visible;
}

svg:not(:root) {
  overflow: visible;
}

.welcomeText{
  font-family: 'Karla', sans-serif !important;
  font-size: 1.5em;
  text-align: center;
  padding-bottom: 25px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.welcomeSubText{
  font-family: 'Karla', sans-serif !important;
  font-size: .75em;
  text-align: center;
  padding-bottom: 25px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 10px;
}

.welcomeList{
  padding-top: 10px;
  padding-bottom: 25px;
  font-family: 'Karla', sans-serif !important;
  font-size: 1.2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.getStarted{
  font-family: 'Karla', sans-serif !important;
  font-size: 1.5em;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.getStartedButton{
  text-align: center;
}

.customButton {
  background: $web-primary !important;
  color: #fff;
  padding: 8px 18px;
  border-radius: 4px;
  line-height: 24px;
  font-size: 24px;
}

.customButton:hover {
  background: rgb(156, 87, 124) !important;
}

