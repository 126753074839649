/* source-serif-pro-200 - latin */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 200;

  src: local(''),
    url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-200.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-200.woff')
      format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-200.ttf')
      format('truetype'); /* Safari, Android, iOS */
}
/* source-serif-pro-200italic - latin */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: italic;
  font-weight: 200;

  src: local(''),
    url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-200italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-200italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-200italic.ttf')
      format('truetype'); /* Safari, Android, iOS */
}
/* source-serif-pro-300italic - latin */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: italic;
  font-weight: 300;

  src: local(''),
    url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-300italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-300italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-300italic.ttf')
      format('truetype'); /* Safari, Android, iOS */
}
/* source-serif-pro-300 - latin */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 300;

  src: local(''),
    url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-300.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-300.woff')
      format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-300.ttf')
      format('truetype'); /* Safari, Android, iOS */
}
/* source-serif-pro-regular - latin */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 400;

  src: local(''),
    url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-regular.ttf')
      format('truetype'); /* Safari, Android, iOS */
}
/* source-serif-pro-italic - latin */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: italic;
  font-weight: 400;

  src: local(''),
    url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-italic.ttf')
      format('truetype'); /* Safari, Android, iOS */
}
/* source-serif-pro-600 - latin */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 600;

  src: local(''),
    url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-600.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-600.woff')
      format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-600.ttf')
      format('truetype'); /* Safari, Android, iOS */
}
/* source-serif-pro-600italic - latin */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: italic;
  font-weight: 600;

  src: local(''),
    url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-600italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-600italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-600italic.ttf')
      format('truetype'); /* Safari, Android, iOS */
}
/* source-serif-pro-700 - latin */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 700;

  src: local(''),
    url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-700.woff')
      format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-700.ttf')
      format('truetype'); /* Safari, Android, iOS */
}
/* source-serif-pro-900 - latin */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 900;

  src: local(''),
    url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-900.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-900.woff')
      format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-900.ttf')
      format('truetype'); /* Safari, Android, iOS */
}
/* source-serif-pro-700italic - latin */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: italic;
  font-weight: 700;

  src: local(''),
    url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-700italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-700italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-700italic.ttf')
      format('truetype'); /* Safari, Android, iOS */
}
/* source-serif-pro-900italic - latin */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: italic;
  font-weight: 900;

  src: local(''),
    url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-900italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-900italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/source-serif-pro/source-serif-pro-v11-latin-900italic.ttf')
      format('truetype'); /* Safari, Android, iOS */
}

/* karla-200 - latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 200;

  src: local(''),
    url('../../assets/fonts/karla/karla-v15-latin-200.woff2') format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/karla/karla-v15-latin-200.woff') format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/karla/karla-v15-latin-200.ttf') format('truetype'); /* Safari, Android, iOS */
}
/* karla-300 - latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 300;

  src: local(''),
    url('../../assets/fonts/karla/karla-v15-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/karla/karla-v15-latin-300.woff') format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/karla/karla-v15-latin-300.ttf') format('truetype'); /* Safari, Android, iOS */
}
/* karla-regular - latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;

  src: local(''),
    url('../../assets/fonts/karla/karla-v15-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/karla/karla-v15-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/karla/karla-v15-latin-regular.ttf')
      format('truetype'); /* Safari, Android, iOS */
}
/* karla-500 - latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 500;

  src: local(''),
    url('../../assets/fonts/karla/karla-v15-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/karla/karla-v15-latin-500.woff') format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/karla/karla-v15-latin-500.ttf') format('truetype'); /* Safari, Android, iOS */
}
/* karla-600 - latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 600;

  src: local(''),
    url('../../assets/fonts/karla/karla-v15-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/karla/karla-v15-latin-600.woff') format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/karla/karla-v15-latin-600.ttf') format('truetype'); /* Safari, Android, iOS */
}
/* karla-700 - latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 700;

  src: local(''),
    url('../../assets/fonts/karla/karla-v15-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/karla/karla-v15-latin-700.woff') format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/karla/karla-v15-latin-700.ttf') format('truetype'); /* Safari, Android, iOS */
}
/* karla-800 - latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 800;

  src: local(''),
    url('../../assets/fonts/karla/karla-v15-latin-800.woff2') format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/karla/karla-v15-latin-800.woff') format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/karla/karla-v15-latin-800.ttf') format('truetype'); /* Safari, Android, iOS */
}
/* karla-200italic - latin */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 200;

  src: local(''),
    url('../../assets/fonts/karla/karla-v15-latin-200italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/karla/karla-v15-latin-200italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/karla/karla-v15-latin-200italic.ttf')
      format('truetype'); /* Safari, Android, iOS */
}
/* karla-300italic - latin */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 300;

  src: local(''),
    url('../../assets/fonts/karla/karla-v15-latin-300italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/karla/karla-v15-latin-300italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/karla/karla-v15-latin-300italic.ttf')
      format('truetype'); /* Safari, Android, iOS */
}
/* karla-italic - latin */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 400;

  src: local(''),
    url('../../assets/fonts/karla/karla-v15-latin-italic.woff2') format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/karla/karla-v15-latin-italic.woff') format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/karla/karla-v15-latin-italic.ttf')
      format('truetype'); /* Safari, Android, iOS */
}
/* karla-600italic - latin */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 600;

  src: local(''),
    url('../../assets/fonts/karla/karla-v15-latin-600italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/karla/karla-v15-latin-600italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/karla/karla-v15-latin-600italic.ttf')
      format('truetype'); /* Safari, Android, iOS */
}
/* karla-500italic - latin */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 500;

  src: local(''),
    url('../../assets/fonts/karla/karla-v15-latin-500italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/karla/karla-v15-latin-500italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/karla/karla-v15-latin-500italic.ttf')
      format('truetype'); /* Safari, Android, iOS */
}
/* karla-700italic - latin */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 700;

  src: local(''),
    url('../../assets/fonts/karla/karla-v15-latin-700italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/karla/karla-v15-latin-700italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/karla/karla-v15-latin-700italic.ttf')
      format('truetype'); /* Safari, Android, iOS */
}
/* karla-800italic - latin */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 800;

  src: local(''),
    url('../../assets/fonts/karla/karla-v15-latin-800italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/karla/karla-v15-latin-800italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/karla/karla-v15-latin-800italic.ttf')
      format('truetype'); /* Safari, Android, iOS */
}
@font-face {
  font-family: 'Baufra';
  src: url('../../assets/fonts/baufra/Baufra-Regular.eot');
  src: local('../../assets/fonts/baufra/Baufra-Regular'),
  url('../../assets/fonts/baufra/Baufra-Regular.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/baufra/Baufra-Regular.woff2') format('woff2'),
  url('../../assets/fonts/baufra/Baufra-Regular.woff') format('woff'),
  url('../../assets/fonts/baufra/Baufra-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Baufra';
  src: url('../../assets/fonts/baufra/Baufra-DemiBold.eot');
  src: local('../../assets/fonts/baufra/Baufra-DemiBold'),
  url('../../assets/fonts/baufra/Baufra-DemiBold.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/baufra/Baufra-DemiBold.woff2') format('woff2'),
  url('../../assets/fonts/baufra/Baufra-DemiBold.woff') format('woff'),
  url('../../assets/fonts/baufra/Baufra-DemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Baufra';
  src: url('../../assets/fonts/baufra/Baufra-SemiBold.eot');
  src: local('../../assets/fonts/baufraBaufra-SemiBold'),
  url('../../assets/fonts/baufra/Baufra-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/baufra/Baufra-SemiBold.woff2') format('woff2'),
  url('../../assets/fonts/baufra/Baufra-SemiBold.woff') format('woff'),
  url('../../assets/fonts/baufra/Baufra-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Baufra';
  src: url('../../assets/fonts/baufra/Baufra-Bold.eot');
  src: local('../../assets/fonts/baufra/Baufra-Bold'),
  url('../../assets/fonts/baufra/Baufra-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/baufra/Baufra-Bold.woff2') format('woff2'),
  url('../../assets/fonts/baufra/Baufra-Bold.woff') format('woff'),
  url('../../assets/fonts/baufra/Baufra-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Baufra';
  src: url('../../assets/fonts/baufra/Baufra-Light.eot');
  src: local('../../assets/fonts/baufra/Baufra-Light'),
  url('../../assets/fonts/baufra/Baufra-Light.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/baufra/Baufra-Light.woff2') format('woff2'),
  url('../../assets/fonts/baufra/Baufra-Light.woff') format('woff'),
  url('../../assets/fonts/baufra/Baufra-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
