@import './syncHealthTheme/syncHealth.scss';

@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'codropsicons';
  src:url('../assets/fonts/codropsicons/codropsicons.eot');
  src:url('../assets/fonts/codropsicons/codropsicons.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/codropsicons/codropsicons.woff') format('woff'),
  url('../assets/fonts/codropsicons/codropsicons.ttf') format('truetype'),
  url('../assets/fonts/codropsicons/codropsicons.svg#codropsicons') format('svg');
}

*, *:after, *:before { -webkit-box-sizing: border-box; box-sizing: border-box; }
.clearfix:before, .clearfix:after { content: ''; display: table; }
.clearfix:after { clear: both; }

html, body, .container {
  height: 100%;
}

body {
  background: #ffffff;
  color: #000000;
  font-weight: 400;
  font-size: 1em;
  font-family: 'Karla', Arial, sans-serif;
  overflow: hidden;
  overflow-y: scroll;
  min-height: 590px;
}
a {
  color: rgba(0,0,0,0.3);
  text-decoration: none;
  outline: none;
}

a:hover, a:focus {
  color: #fff;
}

/* Top Navigation Style */
.codrops-top {
  margin-top: 1em;
}

.codrops-top a {
  font-size: 0.69em;
  padding: 0 0.25em;
  display: inline-block;
  text-decoration: none;
  font-size: 1.2em;
}

.codrops-icon:before {
  margin: 0 4px;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  font-family: 'codropsicons';
  line-height: 1;
  speak: none;
  -webkit-font-smoothing: antialiased;
}

.codrops-icon-drop:before {
  content: "\e001";
}

.codrops-icon-prev:before {
  content: "\e004";
}

.codrops-icon-info:before {
  content: "\e003";
}

.codrops-icon span {
  display: none;
  position: absolute;
  font-size: 0.85em;
  padding: 0.5em 0 0 0.25em;
  font-weight: 700;
}

.codrops-icon:hover span {
  display: block;
  color: #6a7b7e;
}

/* Related demos */
.related {
  font-weight: 700;
  text-align: center;
  padding: 5em 0;
  display: none;
  background: #fff;
  color: rgba(0,0,0,0.3);
}

.overview .related {
  display: block;
}

.related > a {
  border: 3px solid black;
  border-color: initial;
  display: inline-block;
  text-align: center;
  margin: 20px 10px;
  padding: 25px;
}

.related > a:hover,
.related > a:focus {
  color: rgba(0,0,0,0.5);
}

.related a img {
  max-width: 100%;
  opacity: 0.8;
}

.related a:hover img,
.related a:active img {
  opacity: 1;
}

.related a h3 {
  margin: 0;
  padding: 0.5em 0 0.3em;
  max-width: 300px;
  text-align: left;
}

