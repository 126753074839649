@import '../../styles/shared.scss';

$accentcolor: #4d613c;
$lightcolor: #e2e8de;
$darkcolor: #4d613c;







select {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 10px;
  background-color: $lightcolor;
  color: $darkcolor;
  font-size: inherit;
  padding: .5em;
  padding-right: 2.5em;
  border: 2px solid $darkcolor;
  margin: 0;
  border-radius: 3px;
  text-indent: 0.01px;
  text-overflow: '';
  -webkit-appearance: button; /* hide default arrow in chrome OSX */
  &:after,
  &:before {
    content: "";
    position: absolute;
    pointer-events: none;
  }
  &:after{
    content: "\25BC";
    height: 3em;
    font-size: large;
    line-height: 1;
    right: 1.2em;
    top: 50%;
    margin-top: -.5em;
  }
  &:before{
    width: 2em;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 0 3px 3px 0;
  }
}

select:focus {
  outline: #4d613c solid 1px;
}


.radiobtn {
  position: relative;
  display: block;
  label {
    display: block;
    background: lighten($accentcolor, 50%);
    color: $darkcolor;
    border-radius: 5px;
    padding: 10px 42px 10px 20px;
    border: 2px solid lighten($accentcolor, 20%);
    margin-bottom: 5px;
    cursor: pointer;
    width: 50%;
    &:after,
    &:before {
      content: "";
      position: absolute;
      right: 11px;
      top: 11px;
      width: 20px;
      height: 20px;
      border-radius: 3px;
      background: lighten($accentcolor, 15%);
    }
    &:before {
      background: transparent;
      transition: 0.1s width cubic-bezier(0.075, 0.82, 0.165, 1) 0s, 0.3s height cubic-bezier(0.075, 0.82, 0.165, 2) 0.1s;
      z-index: 2;
      overflow: hidden;
      background-repeat: no-repeat;
      background-size: 13px;
      background-position: center;
      width: 0;
      height: 0;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNS4zIDEzLjIiPiAgPHBhdGggZmlsbD0iI2ZmZiIgZD0iTTE0LjcuOGwtLjQtLjRhMS43IDEuNyAwIDAgMC0yLjMuMUw1LjIgOC4yIDMgNi40YTEuNyAxLjcgMCAwIDAtMi4zLjFMLjQgN2ExLjcgMS43IDAgMCAwIC4xIDIuM2wzLjggMy41YTEuNyAxLjcgMCAwIDAgMi40LS4xTDE1IDMuMWExLjcgMS43IDAgMCAwLS4yLTIuM3oiIGRhdGEtbmFtZT0iUGZhZCA0Ii8+PC9zdmc+);
    }
  }
  input[type="radio"] {
    display: none;
    position: absolute;
    width: 100%;
    appearance: none;
    &:checked + label {
      background: lighten($accentcolor, 25%);
      animation-name: blink;
      animation-duration: 1s;
      border-color: $accentcolor;
      &:after {
        background: $accentcolor;
      }
      &:before {
        width: 20px;
        height: 20px;
      }
    }
  }
}

@keyframes blink {
  0% {
    background-color: lighten($accentcolor, 15%);
  }
  10% {
    background-color: lighten($accentcolor, 15%);
  }
  11% {
    background-color: lighten($accentcolor, 20%);
  }
  29% {
    background-color: lighten($accentcolor, 20%);
  }
  30% {
    background-color: lighten($accentcolor, 15%);
  }
  50% {
    background-color: lighten($accentcolor, 20%);
  }
  45% {
    background-color: lighten($accentcolor, 15%);
  }
  50% {
    background-color: lighten($accentcolor, 20%);
  }
  100% {
    background-color: lighten($accentcolor, 15%);
  }
}